<template>
	<div>
		<div class="headbar" style="margin-top:-65px;">
			<div style="float: right;z-index: 2;position: relative;">


				<!-- <el-cascader ref="firstCascader" :options="TreeData" :props="defaultProps" :show-all-levels="true"
					v-model="searchForm.class_name" @change="getSelectType" clearable placeholder="请选择" filterable
					style="width: 250px;margin:0 10px" size="small">
				</el-cascader> -->

				<!-- <el-select size="small" v-model="searchForm.status" placeholder="保险购买" clearable>
					<el-option label="已购保险" :value="1"></el-option>
					<el-option label="未购保险" :value="0"></el-option>
				</el-select> -->



				<el-input placeholder="关键字" v-model="searchForm.keyword" size="small" class="input-with-select"
					style="width:250px;margin-left: 10px" clearable></el-input>



				<el-button icon="el-icon-search" @click="getDataList" style="margin-left:10px" size="small"
					type="primary">搜索</el-button>

					<el-button icon="el-icon-download" @click="downXls" style="margin-left:10px" size="small"
					type="primary">下载</el-button>


				<!-- <el-button :disabled="!searchForm.class_name" icon="el-icon-download" @click="downMingce"
					style="margin-left:10px" size="small" type="primary" title="请先选择班级">下载名册</el-button> -->

				<!-- <el-button v-if="searchForm.school_id" icon="el-icon-data-board" @click="" style="margin-left:10px" size="small"
					type="primary">实习审批表</el-button>
				<el-button  v-if="searchForm.school_id" icon="el-icon-data-board" @click="" style="margin-left:10px" size="small"
					type="primary">实习生名册</el-button>

					<el-button icon="el-icon-data-analysis" @click="dialogShow=true" style="margin-left:10px" size="small"
					type="primary">统计表</el-button> -->

			</div>

		</div>
		<div class="bcontent">


			<div>
				<el-table :data="tableData" row-key="id" border stripe>
					<el-table-column type="index" label="序号" width="55px" align="center"></el-table-column>
					<!-- <el-table-column label="学校" prop="platform" min-width="150" align="center"></el-table-column> -->
					<el-table-column label="姓名" prop="stu_name" width="100" align="center"></el-table-column>
					<el-table-column label="性别" prop="gender" width="80" align="center"></el-table-column>
					<el-table-column label="年龄" prop="age" width="80" align="center"></el-table-column>
					<el-table-column label="户籍所在地（省、市、区/县）" prop="xibu_name" width="150" align="center"></el-table-column>
					<el-table-column label="最高职业技能等级" prop="xibu_name" width="120" align="center"></el-table-column>
					<el-table-column label="全日制升学" align="center">

						<el-table-column label="学校名称" prop="enter_name" width="180" align="center">
							<template slot-scope="scope">
								{{ scope.row.quxiang == '升入高一级学校' ? scope.row.enter_name : '' }}
							</template>
						</el-table-column>
						<el-table-column label="学历" prop="enter_type" width="100" align="center">
							<template slot-scope="scope">
								{{ scope.row.quxiang == '升入高一级学校' ? scope.row.enter_type : '' }}
							</template>
						</el-table-column>

					</el-table-column>
					<!-- <el-table-column label="毕业去向" prop="quxiang" width="180" align="center"></el-table-column> -->
					<el-table-column label="就业状况" align="center">

						<el-table-column label="单位名称" prop="enter_name" width="180" align="center">
							<template slot-scope="scope">
								{{ scope.row.quxiang != '升入高一级学校' ? scope.row.enter_name : '' }}
							</template>
						</el-table-column>
						<el-table-column label="单位地址（省、市、区/县）" prop="enter_addr" width="130"
							align="center"></el-table-column>
						<el-table-column label="单位地点类别" prop="didian" width="120" align="center"></el-table-column>
						<el-table-column label="单位性质" prop="quxiang" min-width="150" align="center"></el-table-column>
						<el-table-column label="所属产业" prop="enter_type" width="120" align="center">
							<template slot-scope="scope">
								{{ scope.row.quxiang != '升入高一级学校' ? scope.row.enter_type : '' }}
							</template>
						</el-table-column>
						<el-table-column label="部门" prop="stu_dept" width="120" align="center"></el-table-column>
						<el-table-column label="岗位" prop="stu_job" width="120" align="center"></el-table-column>
						<el-table-column label="是否对口就业" prop="duikou" width="80" align="center"></el-table-column>
						<el-table-column label="是否学校推荐" prop="qudao" width="80" align="center"></el-table-column>
					</el-table-column>

					<el-table-column label="劳 动 保 障" align="center">

						<el-table-column label="有否劳动合同" prop="hetong" width="80" align="center">
						
						</el-table-column>
						<el-table-column label="有否三险五险" prop="enter_type" width="80" align="center">
							<template slot-scope="scope">
								{{ scope.row.wuxian.replace('一金','') }}
							</template>
						</el-table-column>
						<el-table-column label="有否公积金" prop="gongjijin" width="80" align="center">
							
						</el-table-column>
						<el-table-column label="平均月工资(元)" prop="salary" width="100" align="center"></el-table-column>

					</el-table-column>

					<el-table-column label="是否满意" prop="manyidu" width="80" align="center"></el-table-column>
					<el-table-column label="备注" prop="bz" width="150" align="center"></el-table-column>
					<el-table-column fixed="right" label="操作" width="120">
						<template slot-scope="scope">
							<el-button @click="viewInfo(scope.row)" type="text" size="small">详细</el-button>
							<el-button @click="deleteRow(scope.row)" type="text" size="small">删除</el-button>
						</template>
					</el-table-column>
				</el-table>

				<div style="text-align:right;padding-top: 10px;">
					<el-pagination background @current-change="handlePageChange" :current-page="page.current_page"
						:page-size="page.per_page" :total="page.count" layout="total, prev, pager, next"></el-pagination>
				</div>


			</div>

		</div>

		<jiuyeinfo v-if="infoShow" :id="sxId"></jiuyeinfo>

	</div>
</template>

<script>
import jiuyeinfo from './jiuyeinfo.vue'
export default {
	components:{jiuyeinfo},
	name: "jymingce",
	props: ['tpmid','all'],
	data() {
		return {
			dialogShow: false,
			dialogshow1: false,
			infoShow:false,
			sxId:0,
			searchForm: {
				class_name: "",
				keyword: "",
				status: "",
				school_id: ""

			},
			TargetPmid: 0,
			tableData: [],
			TreeData: [],
			SchoolList: [],
			page: {
				count: 0,
				current_page: 1,
				per_page: 20,
				total_page: 0
			},
			defaultProps: {
				label: 'name',
				value: 'name',
				children: 'children',
				emitPath: false,
				checkStrictly: true,

			},
			shixi: null
		}
	},
	mounted() {

		this.getDataList()
		this.getTree()

	},
	methods: {
		getSchoolList() {
			this.$http.post("/api/school_list").then(res => {
				this.SchoolList = res.data
			})
		},

		getTree() {
			this.$http.post("/api/jymingce_grade_tree", { school_id: this.tpmid }).then(res => {
				this.TreeData = res.data
			})
		},
		getSelectType(e) {
			let node = this.$refs['firstCascader'].getCheckedNodes()
			if (node[0]) {
				let path = node[0].pathNodes[0].label
				if (node[0].pathNodes.length >= 2) {
					this.searchForm.xibu_name = node[0].pathNodes[0].label
					this.searchForm.class_name = node[0].pathNodes[1].label
				} else if (node[0].pathNodes.length >= 1) {
					this.searchForm.xibu_name = node[0].pathNodes[0].label
					this.searchForm.class_name = ""
				} else {
					this.searchForm.xibu_name = ""
					this.searchForm.class_name = ""
				}
			} else {
				this.searchForm.xibu_name = ""
				this.searchForm.class_name = ""

			}

		},
		tongbuData() {
			this.$http.post("/api/tongbu_shixi", { loading: true, tpmid: this.TargetPmid }).then(res => {
				this.$message.success("同步成功")
				this.page.current_page = 1;
				this.getDataList();
			})
		},
		getDataList() {
			let data = {
				page: this.page.current_page,
				keyword: this.searchForm.keyword,
				xibu_name: this.searchForm.xibu_name,
				class_name: this.searchForm.class_name,
				status: this.searchForm.status,
				school_id: this.tpmid,
				showloading: true,
				all:this.all?1:0
			}
			this.$http.post("/api/t_sch_jymingce_list", data).then(res => {
				this.tableData = res.data.data
				this.page = res.data.page
			})


		},
		handlePageChange(page) {
			this.page.current_page = page;
			this.getDataList();
		},
		viewInfo(e) {
			// e.keyword = this.searchForm.keyword
			// e.xibu_name = this.searchForm.xibu_name
			// e.class_name = this.searchForm.class_name
			this.sxId = e.id

			this.infoShow = true
		},
		uploadFiles(e) {
			this.$http.post("/api/import_shixixls", {
				url: e.src
			}).then(res => {

				this.getDataList()
				this.$message.success("导入成功")
				if (res.data.msg.length > 0) {
					let html = ''
					for (let msg of res.data.msg) {
						html += "<div style='font-size:12px'>" + msg.stu_name + ":" + msg.msg + "</div>"
					}
					this.$alert(html, '未导入数据', {
						dangerouslyUseHTMLString: true
					});
				}
				this.dialogshow1 = false


			})
		},
		deleteRow(e) {

			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post("/api/jymingce_delete", { id: e.id }).then(res => {
					this.getDataList()
					this.$message({
						type: 'success',
						message: '删除成功!'
					});

				})
			}).catch(() => {

			});

		},
		downMingce() {

			this.$http.post("/api/jymingce_file_down", { class_name: this.searchForm.class_name, school_id: this.tpmid }).then(res => {
				if (res.data.file) {
					window.open(res.data.file, "_blank")
				} else {
					this.$message({
						type: 'error',
						message: '该班级未上传就业名册!'
					});
				}
			})
		} ,downXls(){
            let data = {
				page: this.page.current_page,
				keyword: this.searchForm.keyword,
				xibu_name: this.searchForm.xibu_name,
				class_name: this.searchForm.class_name,
				status: this.searchForm.status,
				school_id: this.tpmid,
				showloading: true,
				all:this.all?1:0
			}
            this.$http.post("/api/export_jymc", data).then(res => {
               if(res.data.url){
				window.open(res.data.url,"_blank")
			   }

            })
        }

	}
}
</script>
<style scoped></style>
